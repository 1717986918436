// src/components/Print_reciept/Print_reciept.js

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Print.css"; // Import the CSS file
import Loader from "../Confirmation/Loader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Print_reciept = () => {
  const { _id } = useParams();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [paymentIndex, setPaymentIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectdetails, setProjectdetails] = useState(null);

  // Fetch name by endpoint and IDs
  const fetchName = async (endpoint, ...ids) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}/${ids.join("/")}`
      );
      return response.data.data.name;
    } catch (error) {
      console.error(`Error fetching ${endpoint} name:`, error);
      return "Unknown";
    }
  };

  // Fetch unit details
  const fetchUnitDetails = async (projectId, blockId, unitId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getUnit/${projectId}/${blockId}/${unitId}`
      );
      const unitData = response.data.data;
      return {
        unitPrice: unitData.totalPrice,
        idcCharges: unitData.idcCharges,
        plcCharges: unitData.plcCharges,
        plotSize: unitData.plotSize,
        sizeType: unitData.sizeType,
        rate: unitData.rate,
        edcPrice: unitData.edcPrice,
      };
    } catch (error) {
      console.error("Error fetching unit details:", error);
      return {
        unitPrice: "Unknown",
        idcCharges: "Unknown",
        plcCharges: "Unknown",
        plotSize: "Unknown",
        sizeType: "Unknown",
        rate: "Unknown",
        edcPrice: "Unknown",
      };
    }
  };

  // Fetch payment details by customer ID
  const fetchPaymentDetailsByCustomerId = async (customerId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/paymentDetails/${customerId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching payment details:", error);
      return { data: [] };
    }
  };

  // Fetch customer details
  const fetchCustomerDetails = async (customerId) => {
    try {
      const projectsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/getallProjects`
      );
      const projectsData = projectsResponse.data.data || [];
      setProjects(projectsData);

      const customerResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/customer/${customerId}`
      );
      if (customerResponse.data) {
        const matchedCustomer = customerResponse.data;
        const matchedProject = projectsData.find(
          (project) => project._id === matchedCustomer.project
        );
        setProjectdetails(matchedProject || {});

        const projectName = await fetchName(
          "getProject",
          matchedCustomer.project
        );
        const blockName = await fetchName(
          "getBlock",
          matchedCustomer.project,
          matchedCustomer.block
        );
        const unitName = await fetchName(
          "getUnit",
          matchedCustomer.project,
          matchedCustomer.block,
          matchedCustomer.plotOrUnit
        );
        const unitDetails = await fetchUnitDetails(
          matchedCustomer.project,
          matchedCustomer.block,
          matchedCustomer.plotOrUnit
        );
        const paymentDetailsResponse = await fetchPaymentDetailsByCustomerId(
          matchedCustomer.customerId
        );
        const updatedCustomer = {
          ...matchedCustomer,
          projectName: projectName.toUpperCase(),
          blockName: blockName.toUpperCase(),
          unitName: unitName.toUpperCase(),
          paymentDetails: paymentDetailsResponse.data,
          ...unitDetails,
        };
        setCustomerDetails(updatedCustomer);
        setLoading(false);
      } else {
        setError("Invalid customer response structure");
        console.error(
          "Invalid customer response structure:",
          customerResponse.data
        );
      }
    } catch (error) {
      console.error("Error fetching customer details:", error);
      setError("Failed to fetch customer details");
    }
  };

  // Fetch payment and customer details
  const fetchPaymentAndCustomerDetails = async (_id) => {
    try {
      const paymentResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/paymentDetails`
      );

      if (
        paymentResponse.data &&
        paymentResponse.data.data &&
        Array.isArray(paymentResponse.data.data)
      ) {
        const paymentIndex = paymentResponse.data.data.findIndex(
          (payment) => payment._id.toString() === _id.toString()
        );
        if (paymentIndex !== -1) {
          const paymentDetail = paymentResponse.data.data[paymentIndex];
          setPaymentDetails(paymentDetail);
          setPaymentIndex(paymentIndex);
          await fetchCustomerDetails(paymentDetail.customerId);
        } else {
          setError("Payment details not found");
          setLoading(false);
        }
      } else {
        setError("Invalid payment response structure");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching payment details:", error);
      setError("Failed to fetch payment details");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentAndCustomerDetails(_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  // Format date as DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", day: "2-digit", month: "2-digit" };
    return date.toLocaleDateString("en-GB", options); // en-GB for DD/MM/YYYY
  };

  // Convert number to words (simple implementation)
  const convertNumberToWords = (num) => {
    const a = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    const b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num === 0) return "Zero";

    let words = "";

    if (Math.floor(num / 10000000) > 0) {
      words += convertNumberToWords(Math.floor(num / 10000000)) + "Crore ";
      num %= 10000000;
    }

    if (Math.floor(num / 100000) > 0) {
      words += convertNumberToWords(Math.floor(num / 100000)) + "Lakh ";
      num %= 100000;
    }

    if (Math.floor(num / 1000) > 0) {
      words += convertNumberToWords(Math.floor(num / 1000)) + "Thousand ";
      num %= 1000;
    }

    if (Math.floor(num / 100) > 0) {
      words += convertNumberToWords(Math.floor(num / 100)) + "Hundred ";
      num %= 100;
    }

    if (num > 0) {
      if (num < 20) {
        words += a[num];
      } else {
        words += b[Math.floor(num / 10)];
        if (num % 10 > 0) {
          words += " " + a[num % 10];
        }
      }
    }

    return words.trim();
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  // Current Date in DD-MM-YYYY
  const currentDate = new Date();
  const Year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const todaydate = String(currentDate.getDate()).padStart(2, "0");
  const datepattern = `${todaydate}-${month}-${Year}`;

  // Handle Print
  const handlePrint = () => {
    window.print();
  };

  // Handle Download PDF
  const handleDownloadPDF = async () => {
    const input = document.getElementById("print-content");

    // Adjust html2canvas options for better scaling
    const canvas = await html2canvas(input, {
      scale: 3, // Increase scale for better quality
      useCORS: true, // Enable CORS if you have external images
    });

    const imgData = canvas.toDataURL("image/jpeg", 1.0);

    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF in A4 format

    const imgWidth = 210; // A4 size width in mm
    const pageHeight = 295; // A4 size height in mm

    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height with the aspect ratio
    let heightLeft = imgHeight;
    let position = 0;

    // Add the first page
    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add more pages if content overflows
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Save the generated PDF
    pdf.save("Print-receipt.pdf");
  };
  const formatReceiptNumber = (index) => {
    const baseReceiptNumber = 10000;
    const sequentialNumber = baseReceiptNumber + index + 1;
    const prefix = "EBPL101/RCPT";
    return `${prefix}/${sequentialNumber}`;
  };

  // In your receipt rendering code
  const receiptNumber = formatReceiptNumber(paymentIndex);
  const formatAadharNumber = (aadharNumber) => {
    if (!aadharNumber) return "";

    // Insert a space after every 4 characters
    return aadharNumber.replace(/(.{4})/g, "$1 ");
  };
  return (
    <div className="Printcontainer">
      <div className="button-group">
        <button onClick={handlePrint} className="print-button">
          <i className="fas fa-print mr-2"></i> Print
        </button>
        <button className="Download" onClick={handleDownloadPDF}>
          <svg
            className="svgIcon"
            viewBox="0 0 384 512"
            height="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
          </svg>
          Download PDF
        </button>
      </div>

      {/* Receipt Content */}
      <div className="container" id="print-content">
        {/* Header */}
        <div className="header">
          <div className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/WTSlogo.png`}
              className="logo-rect1"
              alt="Company Logo"
            />
            <div className="company-info"></div>
          </div>
          <div className="contact-info">
            <p>
              <i className="fas fa-phone"></i> +91 7042005600
            </p>
            <p>
              <i className="fas fa-envelope"></i> info@ekaksharbuildtech.com
            </p>
            <p>7th Floor : 59, Riana Perito, Sector - 136, Noida </p>
          </div>
        </div>

        <hr className="divider" />

        {/* Title */}
        <h2 className="title">RECEIPT</h2>

        {/* Recipient Information */}
        <div className=" col-12 d-flex justify-content-between ">
          <div className="section col-5 ">
            <p>To,</p>
            <p>{customerDetails.name.toUpperCase()},</p>
            <p>Address:{customerDetails.address}</p>
            <p>Mobile: {customerDetails.mobileNumber}</p>
            <p>Aadhar: {formatAadharNumber(customerDetails.aadharNumber)}</p>
          </div>
          <div className="section flex justify-around">
            <div>
              <p className=" mt-5 d-flex justify-content-end">
                Project Name:{" "}
                <span className="">{customerDetails.projectName}</span>
              </p>
              <p className=" mt-4 ">
                Unit Number:{" "}
                <span className="">
                  {customerDetails.blockName} - {customerDetails.unitName}, Unit
                  Size: {customerDetails.plotSize} {customerDetails.sizeType}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="box-for-recpt mt-3">
            <p>
              Receipt No: <span className="">{receiptNumber}</span>{" "}
            </p>
            <p>
              Receipt Date: <span className="">{datepattern}</span>
            </p>
          </div>
        </div>
        {/* Receipt Details */}
        <div className="borfer-dive-new">
          {/* Payment Details Table */}
          <table>
            <thead>
              <tr>
                <th>Instrument No</th>
                <th>Instrument Date</th>
                <th>Mode</th>
                <th>Total Amount (Rs)</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails.paymentMode && (
                <tr>
                  <td>
                    Ref.No  - {paymentDetails.reference}{" "}
                  </td>
                  <td>{formatDate(paymentDetails.PaymentDate)}</td>
                  <td>{paymentDetails.paymentMode}</td>

                  <td> ₹ {paymentDetails.amount.toLocaleString("en-IN")}</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className="borfer-dive-new1">
            <div className="section">
              <p className=" ms-1">
                Narration: {paymentDetails.reference || "N/A"}
              </p>
            </div>
          </div> */}
        </div>

        <div className="section mt-3">
          <p>
            Amount in Words: Rupees{" "}
            {convertNumberToWords(paymentDetails.amount).replace(
              /([a-z])([A-Z])/g,
              "$1 $2"
            )}{" "}
            Only.
          </p>
        </div>

        {/* Footer */}
        <div className="footer">
          <p>FOR</p>
          <p className="bold">EKAKSHAR BUILDTECH PRIVATE LIMITED</p>
          <br></br>
          <p className=" authority bold ">Authorised Signatory</p>
          <br></br>
          <div className=" d-flex justify-content-between ">
            {" "}
            <p className="gstin">GSTIN: 09AAICE2844D1ZQ </p>{" "}
            <p>PAN: AAICE2844D</p>
          </div>
        </div>

        {/* Decorative Bars */}
        <div className="color-bars">
          <div className="bar bar-gray"></div>
          <div className="bar bar-blue"></div>
          <div className="bar bar-yellow"></div>
          <div className="bar bar-gray"></div>
        </div>
      </div>
    </div>
  );
};

export default Print_reciept;
