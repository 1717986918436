import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Print.css";
import Loader from "../Confirmation/Loader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const DemandDraft = () => {
  const { _id } = useParams();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [payment, setPayment] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectdetails, setProjectdetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjectsAndCustomer = async () => {
      setLoading(true);
      try {
        const projectsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/getallProjects`
        );
        const projectsData = projectsResponse.data.data || [];
        setProjects(projectsData);
        const customerResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/customer`
        );
        const customerData = customerResponse.data;
        const Paymentresponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/paymentDetails`
        );
        const PaymentData = Paymentresponse.data.data || [];
        console.log(Paymentresponse.data.data);
        setPayment(PaymentData);
        const foundCustomer = customerData.find(
          (customer) => customer._id === _id
        );
        setCustomerDetails(foundCustomer);
        const matchedProject = projectsData.find(
          (project) => project._id === foundCustomer.project
        );
        setProjectdetails(matchedProject || {});
        const MatchedPayment = PaymentData.filter(
          (payment) => payment.customerId === foundCustomer.customerId
        );
        setPaymentDetails(MatchedPayment || {});
        const projectName = await fetchName(
          "getProject",
          foundCustomer.project
        );
        const blockName = await fetchName(
          "getBlock",
          foundCustomer.project,
          foundCustomer.block
        );
        const unitName = await fetchName(
          "getUnit",
          foundCustomer.project,
          foundCustomer.block,
          foundCustomer.plotOrUnit
        );
        const unitDetails = await fetchUnitDetails(
          foundCustomer.project,
          foundCustomer.block,
          foundCustomer.plotOrUnit
        );
        const paymentDetailsResponse = await fetchPaymentDetailsByCustomerId(
          foundCustomer.customerId
        );
        const updatedCustomer = {
          ...foundCustomer,
          projectName: projectName.toUpperCase(),
          blockName: blockName.toUpperCase(),
          unitName: unitName.toUpperCase(),
          paymentDetails: paymentDetailsResponse.data,
          ...unitDetails,
        };
        setCustomerDetails(updatedCustomer);

        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchProjectsAndCustomer();
  }, [_id]);

  const fetchName = async (endpoint, ...ids) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}/${ids.join("/")}`
      );
      return response.data.data.name;
    } catch (error) {
      console.error(`Error fetching ${endpoint} name:`, error);
      return "Unknown";
    }
  };

  const fetchUnitDetails = async (projectId, blockId, unitId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getUnit/${projectId}/${blockId}/${unitId}`
      );
      const unitData = response.data.data;
      return {
        unitPrice: unitData.totalPrice,
        idcCharges: unitData.idcCharges,
        plcCharges: unitData.plcCharges,
        plotSize: unitData.plotSize,
        sizeType: unitData.sizeType,
        rate: unitData.rate,
        edcPrice: unitData.edcPrice,
      };
    } catch (error) {
      console.error("Error fetching unit details:", error);
      return {
        unitPrice: "Unknown",
        idcCharges: "Unknown",
        plcCharges: "Unknown",
        plotSize: "Unknown",
        sizeType: "Unknown",
        rate: "Unknown",
        edcPrice: "Unknown",
      };
    }
  };

  const fetchPaymentDetailsByCustomerId = async (customerId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/paymentDetails/${customerId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching payment details:", error);
      return { data: [] };
    }
  };

  const total = customerDetails
    ? parseFloat(customerDetails.plotSize) *
      (parseFloat(customerDetails.idcCharges) +
        parseFloat(customerDetails.plcCharges) +
        parseFloat(customerDetails.edcPrice) +
        parseFloat(customerDetails.rate))
    : "0";

  const calculateTotalAmounts = (customerDetails) => {
    if (
      customerDetails &&
      customerDetails.paymentDetails &&
      customerDetails.paymentDetails.length > 0
    ) {
      return customerDetails.paymentDetails.reduce(
        (sum, payment) => sum + payment.amount,
        0
      );
    } else {
      return 0;
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  var date = new Date();
  var Year = date.getFullYear();
  var month = String(date.getMonth() + 1).padStart(2, "0");
  var todaydate = String(date.getDate()).padStart(2, "0");
  var datepattern = todaydate + "-" + month + "-" + Year;

  const handlePrint = () => {
    window.print();
  };

  const handleDownloadPDF = async () => {
    const input = document.getElementById("print-content");
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgWidth = 210; // A4 size width in mm
    const pageHeight = 295; // A4 size height in mm

    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Calculate the x and y coordinates to center the image
    let x = (imgWidth - imgWidth) / 2; // For horizontal centering
    let y = (pageHeight - imgHeight) / 2; // For vertical centering

    let heightLeft = imgHeight;

    // Add the first page centered
    pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // If there is content left, add more pages
    while (heightLeft >= 0) {
      pdf.addPage();
      pdf.addImage(imgData, "PNG", x, y - heightLeft, imgWidth, imgHeight); // Adjust y to keep centering
      heightLeft -= pageHeight;
    }

    // Save the PDF
    pdf.save("Demand-draft.pdf");
  };

  return (
    <div className="main-pp">
      <div className="button-group d-flex justify-content-end mt-5">
        <button onClick={handlePrint} className="print-button">
          Print
        </button>
        <button className="Download" onClick={handleDownloadPDF}>
          Download
          <svg
            className="svgIcon"
            viewBox="0 0 384 512"
            height="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
          </svg>
          <span className="icon2"></span>
        </button>
      </div>
      <div id="print-content" className="container last ">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/WTSlogo.png`}
            className="logo-rect"
            alt="Company Logo"
          />
          <hr className="divider" />

          <h4 className="text-center h5 mt-2 underline ">
            DUE AMOUNT INTIMATION
          </h4>
          <div className="mt-">
            {customerDetails && projectdetails && (
              <div>
                <div className="row">
                  <div className="col-8">
                    <h6 className="fix-font">
                      {customerDetails.title}{" "}
                      {customerDetails.name.toUpperCase()}
                    </h6>
                    <h6 className="fix-font">{customerDetails.address}</h6>
                    <h6 className="fix-font">
                      Area: {customerDetails.plotSize}{" "}
                      {customerDetails.sizeType}
                    </h6>
                    <h6 className="fix-font">
                      Rate: ₹ {customerDetails.rate}/-
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="fix-font">Demand date: {datepattern}</h6>
                    <h6 className="fix-font">GSTIN NO: {projectdetails.GST}</h6>
                    <h6 className="fix-font">
                      Project Name: {customerDetails.projectName}
                    </h6>
                    <h6 className="fix-font">
                      Unit No {customerDetails.blockName}-
                      {customerDetails.unitName}
                    </h6>
                    <h6 className="fix-font">
                      Email Id: {customerDetails.email}
                    </h6>
                    <h6 className="fix-font">
                      Mobile No: {customerDetails.mobileNumber}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center fix-font mt-2 ">
                    SUBJECT: Payment Dues For the {customerDetails.propertyType}{" "}
                    in {projectdetails.name} {projectdetails.description}
                  </h6>
                  <p className="mt-1 fix-font">
                    Dear Sir/Madam,
                    <br />
                    With reference to your booking of above-mentioned Unit/Plot
                    the following amount is Due as per our Records.
                  </p>
                </div>
                {paymentDetails.length > 0 && (
                  <table className="  border-new" id="demand">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>S.No</th>
                        <th>Particular</th>
                        <th>Due Date</th>
                        <th>Interest</th>
                        <th>Total amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <strong>Total Outstanding Amount</strong>
                        </td>
                        <td></td>
                        <td>{0}</td>
                        <td>
                          ₹{" "}
                          {(
                            total -
                              customerDetails.discount -
                              calculateTotalAmounts(customerDetails) || 0
                          ).toLocaleString("en-IN")}{" "}
                          /-
                        </td>
                      </tr>
                      {/* {(() => {
                        const renderedPaymentTypes = new Set(); 

                        return paymentDetails.map((payment, index) => {
                          const paymentType = parseInt(payment.paymentType, 10);

                          let paymentTypeName;
                          switch (paymentType) {
                            case 1:
                              paymentTypeName = "Booking";
                              break;
                            case 2:
                              paymentTypeName = "First Installment";
                              break;
                            case 3:
                              paymentTypeName = "Second Installment";
                              break;
                            case 4:
                              paymentTypeName = "Third Installment";
                              break;
                            case 5:
                              paymentTypeName = "Fourth Installment";
                              break;
                            case 6:
                              paymentTypeName = "Fifth Installment";
                              break;
                            case 7:
                              paymentTypeName = "Sixth Installment";
                              break;
                            case 8:
                              paymentTypeName = "Seventh Installment";
                              break;
                            case 9:
                              paymentTypeName = "Eighth Installment";
                              break;
                            default:
                              paymentTypeName = "";
                          }

                          if (
                            paymentTypeName &&
                            !renderedPaymentTypes.has(paymentTypeName)
                          ) {
                            renderedPaymentTypes.add(paymentTypeName);
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{paymentTypeName}</td>
                                 <td>{payment.dueDate}</td> 
                               <td>{0}</td> 
                               <td>{payment.amount}</td> 
                              </tr>
                            );
                          }
                          return null;
                        });
                      })()} */}

                      {/* <tr>
                        <td></td>
                        <td>
                          <strong>Total Receivable Amount</strong>
                        </td>
                        <td></td>
                        <td>{0}</td>
                        <td>₹ {total - customerDetails.discount} /-</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <strong>Total Received Amount</strong>
                        </td>
                        <td></td>
                        <td>{0}</td>
                        <td>₹ {calculateTotalAmounts(customerDetails) || 0} /-</td>
                      </tr>
                     */}

                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </tbody>
                  </table>
                )}
                <p className=" ptpadding">
                  *GST as applicable will be charged extra.
                </p>
                <p className="fix-fonta">
                  You are requested to kindly clear above mentioned dues by
                  demand/ Draft/ RTGS drawn in favour of
                  <strong className="fix-font">
                    “EKAKSHAR BUILDTECH PRIVATE LIMITED " A/c No. -
                    {projectdetails.AccountNo}, BANK- {projectdetails.Bank},
                    IFSC-{projectdetails.IFSC} payable at {" "}
                    {projectdetails.Payable.toUpperCase()} at
                  </strong>
                              <p className=" ps-1" >the earliest to avoid further levy the interest charges.</p>
                </p>
                <p className=" content-new">
                  The above-mentioned total due amount is inclusive of any
                  previous outstanding(s). You are therefore requested toremit
                  the above mentioned-amount at the earliest. Company will
                  charge 15% p.a. interest if the payment is delayed by 45 days
                  from the days your demand is raised and further cancellation
                  charges & deductions will apply as per the buyer’s Booking.
                </p>
                <p className=" content-new">Thanks and Regards </p>
                <div className="">
                  <p >
                    <strong> EKAKSHAR BUILDTECH PRIVATE LIMITED </strong>
                  </p>
                  <p className="text-center  content-new okok-text">
                    Authorised Signatory
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="color-bars">
            <div className="bar bar-gray"></div>
            <div className="bar bar-blue"></div>
            <div className="bar bar-yellow"></div>
            <div className="bar bar-gray"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandDraft;
