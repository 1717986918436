import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Noc.css";
import Loader from "../Confirmation/Loader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const NOC = () => {
  const { _id } = useParams();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [payment, setPayment] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectdetails, setProjectdetails] = useState(null);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState('');

  const getCurrentDate = () => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date().toLocaleDateString('en-GB', options);
    return date.replace(',', ''); // Remove comma if needed
  };

  useEffect(() => {
    const updateDate = () => {
      setCurrentDate(getCurrentDate());
    };

    updateDate(); // Initial date set
    const intervalId = setInterval(updateDate, 86400000); // Update every 24 hours

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  useEffect(() => {
    const fetchProjectsAndCustomer = async () => {
      setLoading(true);
      try {
        const projectsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/getallProjects`
        );
        const projectsData = projectsResponse.data.data || [];
        setProjects(projectsData);
        const customerResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/customer`
        );
        const customerData = customerResponse.data;
        const Paymentresponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/paymentDetails`
        );
        const PaymentData = Paymentresponse.data.data || [];
        setPayment(PaymentData);
        const foundCustomer = customerData.find(
          (customer) => customer._id === _id
        );
        setCustomerDetails(foundCustomer);
        console.log(foundCustomer);
        const matchedProject = projectsData.find(
          (project) => project._id === foundCustomer.project
        );
        console.log(matchedProject);
        setProjectdetails(matchedProject || {});
        const MatchedPayment = PaymentData.filter(
          (payment) => payment.customerId === foundCustomer.customerId
        );
        setPaymentDetails(MatchedPayment || {});
        console.log(MatchedPayment);
        const projectName = await fetchName(
          "getProject",
          foundCustomer.project
        );
        const blockName = await fetchName(
          "getBlock",
          foundCustomer.project,
          foundCustomer.block
        );
        const unitName = await fetchName(
          "getUnit",
          foundCustomer.project,
          foundCustomer.block,
          foundCustomer.plotOrUnit
        );
        const unitDetails = await fetchUnitDetails(
          foundCustomer.project,
          foundCustomer.block,
          foundCustomer.plotOrUnit
        );
        const paymentDetailsResponse = await fetchPaymentDetailsByCustomerId(
          foundCustomer.customerId
        );
        const updatedCustomer = {
          ...foundCustomer,
          projectName: projectName.toUpperCase(),
          blockName: blockName.toUpperCase(),
          unitName: unitName.toUpperCase(),
          paymentDetails: paymentDetailsResponse.data,
          ...unitDetails,
        };
        setCustomerDetails(updatedCustomer);

        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchProjectsAndCustomer();
  }, [_id]);

  const fetchName = async (endpoint, ...ids) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}/${ids.join("/")}`
      );
      return response.data.data.name;
    } catch (error) {
      return "Unknown";
    }
  };

  const fetchUnitDetails = async (projectId, blockId, unitId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getUnit/${projectId}/${blockId}/${unitId}`
      );
      const unitData = response.data.data;
      return {
        unitPrice: unitData.totalPrice,
        idcCharges: unitData.idcCharges,
        plcCharges: unitData.plcCharges,
        plotSize: unitData.plotSize,
        sizeType: unitData.sizeType,
        rate: unitData.rate,
        edcPrice: unitData.edcPrice,
      };
    } catch (error) {
      return {
        unitPrice: "Unknown",
        idcCharges: "Unknown",
        plcCharges: "Unknown",
        plotSize: "Unknown",
        sizeType: "Unknown",
        rate: "Unknown",
        edcPrice: "Unknown",
      };
    }
  };

  const fetchPaymentDetailsByCustomerId = async (customerId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/paymentDetails/${customerId}`
      );
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  const total = customerDetails
    ? parseFloat(customerDetails.plotSize) *
      (parseFloat(customerDetails.idcCharges) +
        parseFloat(customerDetails.plcCharges) +
        parseFloat(customerDetails.edcPrice) +
        parseFloat(customerDetails.rate))
    : "0";
  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };
  const handlePrint = () => {
    window.print();
  };
  const handleDownloadPDF = async () => {
    const input = document.getElementById('print-content');
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait mode A4 paper
  
    const imgWidth = 210 - 20; // A4 size width in mm minus margins (10mm on each side)
    const pageHeight = 295 - 20; // A4 size height in mm minus margins (10mm on each side)
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height to maintain aspect ratio
  
    // Starting position for the first image
    let heightLeft = imgHeight;
    let position = 10; // Start 10mm from the top for margin
  
    // Add the first image
    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight); // 10mm margin from the left
    heightLeft -= pageHeight;
  
    // Add subsequent pages
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight + 10; // Adjust position for the new page with margin
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight); // 10mm margin from the left
      heightLeft -= pageHeight;
    }
    
    pdf.save('Ledger.pdf');
  };
  

  return (
    <div className="container white">
      <div className="button-group d-flex justify-content-end mt-5">
        <button onClick={handlePrint} className="print-button">
          Print
        </button>
        <button className="Download" onClick={handleDownloadPDF}>
          Download
          <svg
            className="svgIcon"
            viewBox="0 0 384 512"
            height="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
          </svg>
          <span className="icon2"></span>
        </button>
      </div>
     <div className=" col-12  okok-noc " id='print-content'>
      <div className="header " >
        <div className="flex items-center">
          <img
            src={`${process.env.PUBLIC_URL}/WTSlogo.png`}
            className="logo-rect1"
            alt="Company Logo"
          />
          <div className="company-info"></div>
        </div>
        <div className="contact-info">
          <p>
            <i className="fas fa-phone"></i> +91 7042005600
          </p>
          <p>
            <i className="fas fa-envelope"></i> info@ekaksharbuildtech.com
          </p>
          <p>7th Floor : 59, Riana Perito, Sector - 136, Noida </p>
          <h1 className="h6 mt-5 "> {currentDate}</h1>
        </div>
        

      </div>

      <div className="section col-5 ">
        <p>To,</p>
        <p>{customerDetails.name.toUpperCase()},</p>
        <p>Address:{customerDetails.address}</p>
        <p>Mobile: {customerDetails.mobileNumber}</p>
        <p>Email: {customerDetails.email}</p>
      </div>

      <h1 className=" h3 text-center mt-4 ">NO DUE CERTIFICATE</h1>
      <p> Sir/Madam,</p>
      <p>
        This is to inform you that we have recived the full payment from you  against the allotted {customerDetails.propertyType}  {customerDetails.blockName} Block Unit NO: {customerDetails.unitName} having area of  {customerDetails.plotSize} {customerDetails.sizeType} located on {customerDetails.projectAddress} in the project of our company {customerDetails.projectName} situated at EKAKSHAR BUILDTECH PRIVATE LIMITED. 7th Floor : 59, Riana Perito, Sector - 136, Noida
      </p>
      <p>It is hereby reiterated that, as on the date, there are no outstanding dues payable by I/Us against the booked unit. However, the possession shall only be given in complinces with the provisions of relevent law.</p>
    
     <div className=" dipnew mt-5 " >
      <div> <p>Accepted By </p></div>
      <div><p>EKAKSHAR BUILDTECH PRIVATE LIMITED.</p></div>  
     </div>


     <div className=" dipnews mt-5 " >
      <div className=" mt-5 " > <p>Allottee(s) ___________ </p></div>
      <div className=" mt-5 "><p>Authorized Signatory</p></div>  
     </div>  
     </div>
       </div>
  );
};

export default NOC;